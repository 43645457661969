import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../connection/nn-connection.js';
import '../link/nn-link.js';

/**
 * Use cdn for assets, this needs custom webpack loader with angular imports.
 *
 * const nordnetLogo = new URL('../../assets/nordnet-logo.svg', import.meta.url)
 * .href; */

/**
 * The Prenav molecule contains only links. Connected mode shows customisation of account button with user and userId and one logout button.
 *
 * connected - The Connected boolean variable. If set at true, connected block is shown.
 * user - The user login. Shown in connected mode only.
 * userId - The user ID. Shown in connected mode only.
 * customerUrl - The customer website URL. Url used in connected mode only.
 * loginUrl - The login Connexion URL. URL used in not connected mode only.
 * logoutUrl - The logout Connexion URL. Shown in connected mode only.
 * webmailUrl - The webmail website specific Url.
 * assistanceUrl - The assistance website specific Url.
 */
@customElement('nn-prenav')
export class Prenav extends LitElement {
  static styles = css`
    * {
      box-sizing: border-box;
    }
    svg:not(:root) {
      overflow: hidden;
    }
    .img-responsive {
      color: var(--nn-prenav_img-responsive_color, 323232);
      display: inline-block;
      font-size: var(--nn-prenav_img-responsive_font-size, 0.75em);
      object-fit: cover;
      position: relative;
    }
    .container {
      margin: 0 auto;
      max-width: var(--nn-prenav_container_max-width, 1470px);
      padding: 0 15px;
      width: 100%;
    }
    /* ------- PRENAV --- */
    .prenav {
      background: var(--nn-prenav_prenav_background, #262626);
      font-family: 'font-medium';
      font-size: var(--nn-prenav_prenav_font-size, 16px);
      margin: 0 auto;
      width: 100%;
    }
    .prenav--top {
      align-items: center;
      display: flex;
      height: var(--nn-prenav_prenav--top_height, 49px);
      justify-content: flex-end;
      padding: var(--nn-prenav_prenav--top_padding, 0);
    }
    .prenav .prenav-anchor {
      align-items: center;
      color: var(--nn-prenav_prenav-anchor_color, #f8f8f8);
      display: flex;
      height: var(--nn-prenav_prenav-anchor_height, 26px);
      padding: var(--nn-prenav_prenav-anchor_padding, 5px 10px);
      transition: var(--nn-prenav_prenav-anchor_transition, 0.3s);
    }
    .prenav .icon-inline {
      font-size: var(--nn-prenav_prenav_icon-inline_font-size, 1em);
    }
    .prenav .prenav-anchor span {
      color: var(--nn-prenav_prenav-anchor_span_color, #f8f8f8);
      font-size: var(--nn-prenav_prenav-anchor_span_font-size, 1em);
    }
    .prenav .reverse {
      transform: rotate(180deg);
    }
    .small,
    small {
      font-family: 'font-light';
      font-size: 85%;
      font-style: normal;
    }
    @media (max-width: 767px) {
      .prenav {
        display: none;
      }
    }
    @media (min-width: 768px) {
      .prenav--cta {
        margin-left: 10px;
      }
    }
  `;

  @property({ type: Boolean }) connected = false;

  @property({ type: String }) user = '';

  @property({ type: String }) userId = '';

  @property({ type: String }) customerUrl = 'https://client.nordnet.com';

  @property({ type: String }) loginUrl = 'https://nordnet.com/identification';

  @property({ type: String }) logoutUrl = 'https://nordnet.com#logout';

  @property({ type: String }) webmailUrl = 'https://www.nordnet.com/messagerie';

  @property({ type: String }) assistanceUrl = 'https://assistance.nordnet.com';

  @property({ type: Boolean }) useLogoutAction = false;

  dispatchLogout() {
    this.dispatchEvent(new CustomEvent('dispatchLogout'));
  }

  render() {
    return html`
      <div class="prenav">
        <div class="container">
          <div class="prenav--top">
            <nn-link
              textLink="Nous contacter"
              idLink="prenav-contact-link"
              hrefLink="tel:3420"
              class="prenav-anchor"
            >
              <svg
                slot="icon"
                class="img-responsive"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <title>Nous contacter</title>
                <path
                  d="M19.75 8.1462C20.2031 8.1462 20.6376 8.3262 20.958 8.64659C21.2784 8.96698 21.4584 9.40152 21.4584 9.85462V13.2715C21.4584 13.7246 21.2784 14.1591 20.958 14.4795C20.6376 14.7999 20.2031 14.9799 19.75 14.9799H18.8429C18.6347 16.6313 17.8309 18.15 16.5826 19.2509C15.3342 20.3518 13.727 20.9593 12.0625 20.9593V19.2509C13.4218 19.2509 14.7253 18.7109 15.6864 17.7498C16.6476 16.7886 17.1875 15.485 17.1875 14.1257V9.00041C17.1875 7.64111 16.6476 6.33748 15.6864 5.37631C14.7253 4.41514 13.4218 3.87516 12.0625 3.87516C10.7033 3.87516 9.39972 4.41514 8.4386 5.37631C7.47747 6.33748 6.93752 7.64111 6.93752 9.00041V14.9799H4.37502C3.92194 14.9799 3.48742 14.7999 3.16705 14.4795C2.84667 14.1591 2.66669 13.7246 2.66669 13.2715V9.85462C2.66669 9.40152 2.84667 8.96698 3.16705 8.64659C3.48742 8.3262 3.92194 8.1462 4.37502 8.1462H5.28215C5.49059 6.49493 6.2944 4.97647 7.54276 3.87574C8.79112 2.77501 10.3982 2.16766 12.0625 2.16766C13.7268 2.16766 15.3339 2.77501 16.5823 3.87574C17.8306 4.97647 18.6345 6.49493 18.8429 8.1462H19.75ZM8.44085 14.7962L9.34627 13.3475C10.1604 13.8575 11.1019 14.1272 12.0625 14.1257C13.0231 14.1272 13.9647 13.8575 14.7788 13.3475L15.6842 14.7962C14.5988 15.4763 13.3434 15.836 12.0625 15.8341C10.7817 15.836 9.52628 15.4763 8.44085 14.7962Z"
                  fill="#f8f8f8"
                ></path>
              </svg>
              <span slot="text">
                3420
                <small>(service gratuit +&nbsp;prix d'appel)</small>
              </span>
            </nn-link>
            <nn-link
              textlink="Assistance"
              idLink="prenav-assistance-link"
              .hrefLink="${this.assistanceUrl}"
              class="prenav-anchor"
            >
              <svg
                slot="icon"
                class="img-responsive"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>assistance</title>
                <path
                  d="M5.35827 1.60402L6.66132 4.80328C5.7239 5.29355 4.95876 6.05868 4.46849 6.99609L1.26919 5.69304C2.11709 3.89731 3.56251 2.45191 5.35827 1.60402ZM5.59651 1.00598C4.48945 1.45851 3.48369 2.12715 2.63801 2.97282C1.79232 3.8185 1.12368 4.82424 0.671143 5.93129L4.69701 7.57954C4.93051 7.00654 5.27618 6.48599 5.7137 6.04848C6.15123 5.61096 6.67178 5.2653 7.24478 5.0318L5.59651 1.00598Z"
                  fill="#F8F8F8"
                ></path>
                <path
                  d="M12.6418 1.60402C14.4375 2.45191 15.8829 3.89731 16.7308 5.69304L13.5315 6.99609C13.0413 6.05868 12.2761 5.29355 11.3387 4.80328L12.6418 1.60402ZM12.4035 1.00598L10.7552 5.0318C11.3283 5.2653 11.8488 5.61096 12.2863 6.04848C12.7239 6.48599 13.0695 7.00654 13.303 7.57954L17.3289 5.93129C16.8764 4.82424 16.2077 3.8185 15.362 2.97282C14.5163 2.12715 13.5106 1.45851 12.4035 1.00598Z"
                  fill="#F8F8F8"
                ></path>
                <path
                  d="M4.46849 11.6732C4.95876 12.6106 5.7239 13.3757 6.66132 13.866L5.35827 17.0652C3.56251 16.2173 2.11709 14.7719 1.26919 12.9762L4.46849 11.6732ZM4.69701 11.0897L0.671143 12.738C1.12368 13.845 1.79232 14.8508 2.63801 15.6964C3.48369 16.5421 4.48945 17.2107 5.59651 17.6633L7.24478 13.6375C6.67178 13.404 6.15123 13.0583 5.7137 12.6208C5.27618 12.1833 4.93051 11.6627 4.69701 11.0897Z"
                  fill="#F8F8F8"
                ></path>
                <path
                  d="M13.5315 11.6732L16.7308 12.9762C15.8829 14.7719 14.4375 16.2173 12.6418 17.0652L11.3387 13.866C12.2761 13.3757 13.0413 12.6106 13.5315 11.6732ZM13.303 11.0897C13.0695 11.6627 12.7239 12.1833 12.2863 12.6208C11.8488 13.0583 11.3283 13.404 10.7552 13.6375L12.4035 17.6633C13.5106 17.2107 14.5163 16.5421 15.362 15.6964C16.2077 14.8508 16.8764 13.845 17.3289 12.738L13.303 11.0897Z"
                  fill="#F8F8F8"
                ></path>
                <path
                  d="M8.99995 13.9827C8.39785 13.9842 7.80137 13.8669 7.24471 13.6375L5.59644 17.6633C7.77722 18.5581 10.2227 18.5581 12.4035 17.6633L10.7552 13.6375C10.1985 13.8669 9.60204 13.9842 8.99995 13.9827Z"
                  fill="#F8F8F8"
                ></path>
                <path
                  d="M8.99995 4.68607C9.60204 4.68453 10.1985 4.80184 10.7552 5.03128L12.4035 1.00546C10.2227 0.110647 7.77722 0.110647 5.59644 1.00546L7.24471 5.03128C7.80137 4.80184 8.39785 4.68453 8.99995 4.68607Z"
                  fill="#F8F8F8"
                ></path>
                <path
                  d="M13.303 7.5794C13.7634 8.70426 13.7634 9.96498 13.303 11.0898L17.3288 12.7381C18.2237 10.5573 18.2237 8.11191 17.3288 5.93115L13.303 7.5794Z"
                  fill="#F8F8F8"
                ></path>
                <path
                  d="M4.35177 9.33462C4.35023 8.73253 4.46755 8.13606 4.69699 7.5794L0.67112 5.93115C-0.223707 8.11191 -0.223707 10.5573 0.67112 12.7381L4.69699 11.0898C4.46755 10.5332 4.35023 9.93671 4.35177 9.33462Z"
                  fill="#F8F8F8"
                ></path>
              </svg>
              <span slot="text">Assistance</span>
            </nn-link>
            <nn-link
              textlink="Ma messagerie"
              idLink="prenav-messagerie-link"
              .hrefLink="${this.webmailUrl}"
              class="prenav-anchor"
            >
              <svg
                slot="icon"
                class="img-responsive"
                width="19"
                height="15"
                viewBox="0 0 19 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Ma messagerie</title>
                <path
                  d="M18.0575 0.251526H1.4713C1.11845 0.251526 0.833374 0.535901 0.833374 0.88789V13.6152C0.833374 13.9672 1.11845 14.2515 1.4713 14.2515H18.0575C18.4104 14.2515 18.6954 13.9672 18.6954 13.6152V0.88789C18.6954 0.535901 18.4104 0.251526 18.0575 0.251526ZM17.2601 2.45493V12.8197H2.26872V2.45493L1.7185 2.02738L2.50196 1.02312L3.35519 1.68533H16.1756L17.0288 1.02312L17.8123 2.02738L17.2601 2.45493ZM16.1756 1.68334L9.76441 6.65493L3.3532 1.68334L2.49997 1.02113L1.71651 2.02539L2.26673 2.45295L9.07664 7.73477C9.27248 7.88654 9.51342 7.96893 9.76142 7.96893C10.0094 7.96893 10.2504 7.88654 10.4462 7.73477L17.2601 2.45493L17.8103 2.02738L17.0269 1.02312L16.1756 1.68334Z"
                  fill="white"
                ></path>
              </svg>
              <span slot="text">Ma messagerie</span>
            </nn-link>
            <nn-connection
              .connected="${this.connected}"
              .user="${this.user}"
              .userId="${this.userId}"
              .customerUrl="${this.customerUrl}"
              .logoutUrl="${this.logoutUrl}"
              .useLogoutAction="${this.useLogoutAction}"
              @logout="${this.dispatchLogout}"
            ></nn-connection>
            </div>
          </div>
        </div>
      </div>
    `;
  }
}
